var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.saveCarcassMaterial.apply(null, arguments)
        }
      }
    },
    [
      _vm.errors.length > 0
        ? _c(
            "div",
            {
              staticClass: "alert alert-danger flex-column",
              attrs: { id: "error_explanation" }
            },
            [
              _c("h4", [_vm._v("Could not nominate this carcass material")]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "mb-0" },
                _vm._l(_vm.errors, function(error) {
                  return _c("li", { key: error }, [_vm._v(_vm._s(error))])
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c("h4", [_vm._v("Material Filters")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Apply filters and then nominate which material can be used as a carcass material."
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Material Type")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.material_type_id,
                      expression: "material_type_id"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.material_type_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("None")]),
                  _vm._v(" "),
                  _vm._l(_vm.material_types, function(m) {
                    return _c(
                      "option",
                      { key: m.id, domProps: { value: m.id } },
                      [_vm._v(_vm._s(m.name))]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Brand")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.brand_id,
                      expression: "brand_id"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.brand_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("None")]),
                  _vm._v(" "),
                  _vm._l(_vm.brands, function(b) {
                    return _c(
                      "option",
                      { key: b.id, domProps: { value: b.id } },
                      [_vm._v(_vm._s(b.name))]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Range")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.brand_range_id,
                      expression: "brand_range_id"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.brand_range_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("None")]),
                  _vm._v(" "),
                  _vm._l(_vm.brand_ranges, function(br) {
                    return _c(
                      "option",
                      { key: br.id, domProps: { value: br.id } },
                      [_vm._v(_vm._s(br.name))]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Group")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.brand_group_id,
                      expression: "brand_group_id"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.brand_group_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("None")]),
                  _vm._v(" "),
                  _vm._l(_vm.brand_groups, function(rg) {
                    return _c(
                      "option",
                      { key: rg.id, domProps: { value: rg.id } },
                      [_vm._v(_vm._s(rg.name))]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Features")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.feature_id,
                      expression: "feature_id"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.feature_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("None")]),
                  _vm._v(" "),
                  _vm._l(_vm.features, function(f) {
                    return _c(
                      "option",
                      { key: f.id, domProps: { value: f.id } },
                      [_vm._v(_vm._s(f.name))]
                    )
                  })
                ],
                2
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", [
        _c("table", { staticClass: "table" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.materials, function(material, index) {
              return _c("material-row", {
                key: material.id,
                attrs: {
                  material: material,
                  toggleMaterialSelect: _vm.toggleMaterialSelect,
                  setDefault: _vm.setDefault,
                  index: index
                }
              })
            }),
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.error.length > 0
        ? _c(
            "div",
            {
              staticClass: "alert alert-warning fade show",
              attrs: { role: "alert" }
            },
            [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "alert-text" }, [
                _vm._v(_vm._s(_vm.error))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "alert-close" }, [
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button", "aria-label": "Close" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closeError.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._m(3)]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.successMessage.length > 0
        ? _c(
            "div",
            {
              staticClass: "alert alert-success fade show",
              attrs: { role: "alert" }
            },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("div", { staticClass: "alert-text" }, [
                _c("p", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.successMessage) + ".\n        "
                  ),
                  _c("a", { attrs: { href: "/admin/carcass_materials" } }, [
                    _vm._v("Click here to return to the carcass material list.")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "alert-close" }, [
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button", "aria-label": "Close" },
                    on: {
                      click: function($event) {
                        var this$1 = this
                        $event.preventDefault()
                        return function() {
                          return (this$1.successMessage = "")
                        }.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._m(5)]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(6)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h4", [_vm._v("Filtered Materials")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Material")]),
        _vm._v(" "),
        _c("th", [_vm._v("Style")]),
        _vm._v(" "),
        _c("th", [_vm._v("Dimensions")]),
        _vm._v(" "),
        _c("th", [_vm._v("Default")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "la la-warning" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { attrs: { "aria-hidden": "true" } }, [
      _c("i", { staticClass: "la la-close" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "la la-check-circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { attrs: { "aria-hidden": "true" } }, [
      _c("i", { staticClass: "la la-close" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        { staticClass: "btn btn-brand", attrs: { type: "submit" } },
        [_vm._v("Nominate Carcass Material")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }