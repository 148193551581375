<template>
  <tr>
    <td>
      <span style="width: 40px;">
        <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid">
          <input type="checkbox" :checked="material.selected" @click="toggleMaterialSelect(index)" />&nbsp;
          <span></span>
        </label>
      </span>
    </td>
    <td>{{ material.material_type }}</td>
    <td>
      <span>
        <div class="kt-user-card-v2">
          <div class="kt-user-card-v2__details">
            <div class="kt-user-card-v2__name">{{ material.name }}</div>
            <div
              class="kt-user-card-v2__email"
            >{{ material.brand }} - {{ brandRange }}</div>
          </div>
        </div>
      </span>
    </td>
    <td>{{ material.style.colour }} {{ material.style.finish }}</td>
    <td style="text-transform: capitalize;">{{ dimensionDisplay }}</td>
    <td>
      <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid">
        <input type="checkbox" :checked="material.default" @click="setDefault($event, index)" />&nbsp;
        <span></span>
      </label>
    </td>
  </tr>
</template>
<script>
export default {
  props: ["material", "index", "toggleMaterialSelect", "setDefault"],
  computed: {
    brandRange() {
      if (this.material.brand_ranges.length === 0) {
        return 'No Range Set';
      }
      return this.material.brand_ranges.map(br => br.name).join(', ');
    },
    dimensionDisplay() {
      return Object.keys(this.material.dimensions)
        .filter(key => {
          return this.material.dimensions[key] !== 0;
        })
        .map(key => {
          return `${key}: ${this.material.dimensions[key]}mm`;
        })
        .join(" ");
    }
  }
};
</script>
