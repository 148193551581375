/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@hotwired/turbo-rails"
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Rails from '@rails/ujs';
import Vue from 'vue/dist/vue.esm';
import CarcassMaterialApp from '../src/carcass_material/app.vue';
import Alpine from 'alpinejs';

Rails.start();
require('bootstrap');
require('typeahead.js');
require('bootstrap-datepicker');

import '../src/vendor/a-sticky.min.js';
import '../src/vendor/perfect-scrollbar.min.js';
// import '../src/vendor/scripts.bundle.js';

// Stimulus import
import '../controllers';

import {
  clearInput,
  clearSelectInput,
  setTags,
  updateTags,
} from '../src/utilities';
import assemblyComponents from '../src/pages/assemblyComponents';
import cabinetComponents from '../src/pages/cabinetComponents';
import estimateCabinets from '../src/pages/estimateCabinets';
import estimates from '../src/pages/estimates';
import materials from '../src/pages/materials';
import pricingForm from '../src/pages/pricingForm';
import operationForm from '../src/pages/operationForm';
import orders from '../src/pages/orders';

// Set global utility functions for Rails views:
window.clearInput = clearInput;
window.clearSelectInput = clearSelectInput;
window.setTags = setTags;
window.updateTags = updateTags;

$(document).on('turbo:load', function () {
  window.Alpine = Alpine;
  Alpine.start();

  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover({
    trigger: 'hover',
  });

  var carcassMaterialForm = document.getElementById('carcass-material-form');
  var cabinetForm = document.getElementById('cabinet-form');

  // Import view-specific JS
  assemblyComponents();
  cabinetComponents();
  estimateCabinets();
  estimates();
  materials();
  pricingForm();
  operationForm();
  orders();

  if (carcassMaterialForm) {
    const app = new Vue({
      el: '#carcass-material-form',
      components: { CarcassMaterialApp },
      template: '<CarcassMaterialApp />',
    });
  }

  $(document).on('click', '.remove_fields', function (event) {
    event.preventDefault();
    const datestamp = $(this).data('type');

    if (datestamp) {
      $(this).prev('input[type=hidden]').val(datestamp);
    } else {
      $(this).prev('input[type=hidden]').val('1');
    }

    $(this).closest('.row').hide();
  });

  $(document).on('click', '.restore_fields', function (event) {
    event.preventDefault();

    $(this).prev('input[type=hidden]').val("");
    console.log($(this).prev('input[type=hidden]').val());
  });

  $('form').on('click', '.add_fields', function (event) {
    event.preventDefault();
    var time = new Date().getTime();
    var regexp = new RegExp($(this).data('id'), 'g');
    $(this).closest('.row').after($(this).data('fields').replace(regexp, time));
  });

  const mobileSidebar = document.getElementById('kt_header_menu_wrapper');
  const mobileToggle = document.getElementById('kt_header_mobile_toggler');
  const mobileSubMenuToggles = document.querySelectorAll('.kt-menu__toggle');

  mobileSubMenuToggles.forEach(sm => {
    sm.addEventListener('click', function () {
      sm.parentElement.querySelectorAll('.kt-menu__item').forEach(mi => {
        mi.classList.toggle('kt-menu__item--open');
      });

      sm.parentElement.classList.toggle('kt-menu__item--open-dropdown');
      sm.parentElement.classList.toggle('kt-menu__item--hover');
    });
  });
});

// Import styles
import '../stylesheets/application.css';
