export default () => {
  var materialForm = $('#material-form');

  if (materialForm.length) {
    $('form').on('click', '.add_fields', function(e) {
      e.preventDefault();

      var time = new Date().getTime();
      var regexp = new RegExp($(this).data('id'), 'g');
      $(this)
        .parent()
        .parent()
        .after(
          $(this)
            .data('fields')
            .replace(regexp, time)
        );
    });
  }
};
