export default () => {
  const cabinetComponentPage = $('#cabinet-component-form');

  if (cabinetComponentPage.length) {
    // Adding new material options to the assembly component
    $('[data-form-prepend]').click(function(e) {
      e.preventDefault();

      var obj = $($(this).attr('data-form-prepend'));
      obj.find('input, select, textarea').each(function() {
        $(this).attr('name', function() {
          return $(this)
            .attr('name')
            .replace('new_record', new Date().getTime());
        });
      });
      obj.insertBefore(this);
      return false;
    });

    $('form').on('click', '.add_fields', function(e) {
      e.preventDefault();

      var time = new Date().getTime();
      var regexp = new RegExp($(this).data('id'), 'g');

      $('#show-chosen-materials').append(
        $(this)
          .data('fields')
          .replace(regexp, time)
      );
    });

    // Choosing whether to use a category or individual items for the component
    $('#assembly-component-association').change(function(e) {
      if (e.target.value === 'materials') {
        $('#assembly_component_material_type option:selected').each(function() {
          $(this).prop('selected', false);
        });
        $('#show-categories').hide();
        $('#show-chosen-materials').show();
        $('#show-group-materials').hide();
        $('#material-type-options').hide();
      } else {
        // Show the material type + group options
        $('#show-categories').show();
        $('#show-chosen-materials').hide();
        $('#material-type-options').show();
        $('#show-group-materials').show();
      }
    });

    $('#material-type-options').change(function(e) {
      var materialType = e.target.value;
      var details = $('#assembly-details');

      var url = `/admin/assemblies/${details.data(
        'assembly-id'
      )}/assembly_components/${details.data(
        'id'
      )}/material_type/${materialType}`;

      $.get({ url });
    });
  }
};
