export default () => {
  var cabPage = $('#estimate-cabinets-page');

  if (cabPage) {
    addListenerToEditMaterials();

    function addListenerToEditMaterials() {
      var editMaterial = $('.edit-material');

      editMaterial.click(function(e) {
        e.preventDefault();
        var materialType = $(this).data('type');
        var id = $(this).data('id');

        requestMaterials($(this), materialType, id);
      });
    }

    function requestMaterials(node, type, id) {
      var url = `/admin/materials/type/${type}`;

      $.get({ url }).done((res) => {
        createMaterialOptions(node, res, type, id);
      });
    }

    function createMaterialOptions(node, data, type, id) {
      var formattedOptions = data
        .map((d) => {
          return `<option value="${d.id}">${d.name} - ${d.oem}</option>`;
        })
        .join('');
      formattedOptions =
        '<option selected="true" disabled>Select The New Material</option>' +
        formattedOptions;

      var selectField = `<select data-type="${type}" id="${id}" class="material-options form-control">${formattedOptions}</select>`;
      node.parent().replaceWith(selectField);

      var materialOptions = $('.material-options');

      materialOptions.change(function(e) {
        var newMaterial = $(e.target).val();

        saveMaterial($(e.target), newMaterial, id);
      });
    }

    function saveMaterial(node, materialId, id) {
      var url = `/admin/estimate_cabinet/component/${id}/material`;
      var data = {
        material_id: materialId,
        authenticity_token: $('[name="csrf-token"]')[0].content,
      };

      $.ajax({ type: 'Patch', url, data }).done((res) => {
        var newElem = `<div>${res.name} - ${res.oem}<a class="edit-material" href="#" data-type="${res.type}" data-id="${res.id}"><i class="las la-edit"></i></a></div>`;

        node.replaceWith(newElem);
        addListenerToEditMaterials();
      });
    }
  }
};
