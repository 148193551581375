import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="qa-check"
export default class extends Controller {
  url = `/admin/qa_checks`;
  static values = {
    checkableId: Number,
    qaType: String,
    id: Number
  }
  static targets = ['passCheck', 'failCheck']

  pass(e) {
    if (e.target.checked) {
      // The user has checked for QA
      const data = {
        qa_check: {
          checkable_id: this.checkableIdValue,
          checkable_type: this.qaTypeValue,
          passed: e.target.checked
        },
        authenticity_token: document.getElementsByName('csrf-token')[0].content
      }

      fetch(this.url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(res => res.json())
        .then(json => {
          this.idValue = json.id;
          this.failCheckTarget.removeAttribute('checked');
        });
    } else {
      fetch(
        `${this.url}/${this.idValue}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': document.getElementsByName('csrf-token')[0].content
          },
        })
        .then(res => res.json())
        .then(() => {
          this.idValue = '';
        })
    }
  }

  fail(e) {
    if (e.target.checked) {
      // The user has checked for QA
      const data = {
        qa_check: {
          checkable_id: this.checkableIdValue,
          checkable_type: this.qaTypeValue,
          passed: false
        },
        authenticity_token: document.getElementsByName('csrf-token')[0].content
      }

      fetch(this.url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(res => res.json())
        .then(json => {
          this.passCheckTarget.removeAttribute('checked');
          this.idValue = json.id;
        });
    } else {
      fetch(
        `${this.url}/${this.idValue}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': document.getElementsByName('csrf-token')[0].content
          },
        })
        .then(res => res.json())
        .then(() => {
          this.idValue = '';
        })
    }
  }
}
