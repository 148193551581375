export default () => {
  const labourRoleEl = document.getElementById('operation_labour_role_id');
  const costingEl = document.getElementById('labour_role_billable');

  if (labourRoleEl && costingEl) {
    labourRoleEl.addEventListener('change', e => {
      const billable = e.target.options[e.target.selectedIndex].dataset.billable;
      
      if (billable == 'true') {
        costingEl.innerText = 'Costing Operation';
      } else {
        costingEl.innerText = 'Scheduling Operation';
      }
    });
  }
};