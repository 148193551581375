var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c("span", { staticStyle: { width: "40px" } }, [
        _c(
          "label",
          { staticClass: "kt-checkbox kt-checkbox--single kt-checkbox--solid" },
          [
            _c("input", {
              attrs: { type: "checkbox" },
              domProps: { checked: _vm.material.selected },
              on: {
                click: function($event) {
                  return _vm.toggleMaterialSelect(_vm.index)
                }
              }
            }),
            _vm._v(" \n        "),
            _c("span")
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.material.material_type))]),
    _vm._v(" "),
    _c("td", [
      _c("span", [
        _c("div", { staticClass: "kt-user-card-v2" }, [
          _c("div", { staticClass: "kt-user-card-v2__details" }, [
            _c("div", { staticClass: "kt-user-card-v2__name" }, [
              _vm._v(_vm._s(_vm.material.name))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "kt-user-card-v2__email" }, [
              _vm._v(
                _vm._s(_vm.material.brand) + " - " + _vm._s(_vm.brandRange)
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("td", [
      _vm._v(
        _vm._s(_vm.material.style.colour) +
          " " +
          _vm._s(_vm.material.style.finish)
      )
    ]),
    _vm._v(" "),
    _c("td", { staticStyle: { "text-transform": "capitalize" } }, [
      _vm._v(_vm._s(_vm.dimensionDisplay))
    ]),
    _vm._v(" "),
    _c("td", [
      _c(
        "label",
        { staticClass: "kt-checkbox kt-checkbox--single kt-checkbox--solid" },
        [
          _c("input", {
            attrs: { type: "checkbox" },
            domProps: { checked: _vm.material.default },
            on: {
              click: function($event) {
                return _vm.setDefault($event, _vm.index)
              }
            }
          }),
          _vm._v(" \n      "),
          _c("span")
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }