<template>
  <form @submit.prevent="saveCarcassMaterial">
    <div v-if="errors.length > 0" id="error_explanation" class="alert alert-danger flex-column">
      <h4>Could not nominate this carcass material</h4>
      <ul class="mb-0">
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </div>
    <div>
      <h4>Material Filters</h4>
      <p>Apply filters and then nominate which material can be used as a carcass material.</p>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Material Type</label>
            <select v-model="material_type_id" class="form-control">
              <option value>None</option>
              <option v-for="m in material_types" :key="m.id" :value="m.id">{{ m.name }}</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Brand</label>
            <select v-model="brand_id" class="form-control">
              <option value>None</option>
              <option v-for="b in brands" :key="b.id" :value="b.id">{{ b.name }}</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Range</label>
            <select v-model="brand_range_id" class="form-control">
              <option value>None</option>
              <option v-for="br in brand_ranges" :key="br.id" :value="br.id">{{ br.name }}</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Group</label>
            <select v-model="brand_group_id" class="form-control">
              <option value>None</option>
              <option v-for="rg in brand_groups" :key="rg.id" :value="rg.id">{{ rg.name }}</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Features</label>
            <select v-model="feature_id" class="form-control">
              <option value>None</option>
              <option v-for="f in features" :key="f.id" :value="f.id">{{ f.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h4>Filtered Materials</h4>
      </div>
    </div>
    <div>
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Type</th>
            <th>Material</th>
            <th>Style</th>
            <th>Dimensions</th>
            <th>Default</th>
          </tr>
        </thead>
        <tbody>
          <material-row
            v-for="(material, index) in materials"
            :key="material.id"
            :material="material"
            :toggleMaterialSelect="toggleMaterialSelect"
            :setDefault="setDefault"
            :index="index"
          ></material-row>
        </tbody>
      </table>
    </div>
    <br />
    <div v-if="error.length > 0" class="alert alert-warning fade show" role="alert">
      <div class="alert-icon">
        <i class="la la-warning"></i>
      </div>
      <div class="alert-text">{{ error }}</div>
      <div class="alert-close">
        <button type="button" class="close" aria-label="Close" @click.prevent="closeError">
          <span aria-hidden="true">
            <i class="la la-close"></i>
          </span>
        </button>
      </div>
    </div>
    <div v-if="successMessage.length > 0" class="alert alert-success fade show" role="alert">
      <div class="alert-icon">
        <i class="la la-check-circle"></i>
      </div>
      <div class="alert-text">
        <p>
          {{ successMessage }}.
          <a href="/admin/carcass_materials">Click here to return to the carcass material list.</a>
        </p>
      </div>
      <div class="alert-close">
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click.prevent="() => (this.successMessage = '')"
        >
          <span aria-hidden="true">
            <i class="la la-close"></i>
          </span>
        </button>
      </div>
    </div>
    <div class="actions">
      <button type="submit" class="btn btn-brand">Nominate Carcass Material</button>
    </div>
  </form>
</template>
<script>
import axios from "axios";
import materialRow from "./materialRow";

export default {
  components: { "material-row": materialRow },
  data() {
    return {
      id: "",
      brands: [],
      brand_id: "",
      brand_ranges: [],
      brand_range_id: "",
      brand_groups: [],
      brand_group_id: "",
      material_types: [],
      material_type_id: "",
      materials: [],
      features: [],
      feature_id: "",
      material_location: "",
      error: "",
      successMessage: "",
      errors: [],
      toggleAll: true
    };
  },
  created() {
    this.fetchBrands();
    this.fetchMaterialTypes();
    this.fetchFeatures();
  },
  watch: {
    material_type_id: function(id) {
      this.fetchMaterials();
    },
    brand_id: function(id) {
      this.brand_range_id = '';

      if (id === "") {
        this.brand_ranges = [];
        this.brand_groups = [];
        this.features = [];
      }

      this.fetchMaterials();
      this.fetchRanges();
      this.fetchFeatures();
    },
    brand_range_id: function(id) {
      if (id === "") {
        this.brand_groups = [];
        this.fetchMaterials();
        return;
      }

      this.fetchMaterials();
      this.fetchGroups();
    },
    brand_group_id: function(id) {
      this.fetchMaterials();
    },
    feature_id: function() {
      this.fetchMaterials();
    }
  },
  methods: {
    fetchBrands: function() {
      var url = "/admin/brands";

      this.apiCall(url).then(res => (this.brands = res.data));
    },

    fetchMaterialTypes: function() {
      var url = "/admin/settings/material_types";

      this.apiCall(url).then(res => (this.material_types = res.data));
    },

    fetchRanges: function() {
      if (!this.brand_id) return;
      var url = `/admin/brands/${this.brand_id}/ranges`;

      this.apiCall(url).then(res => (this.brand_ranges = res.data));
    },

    fetchGroups: function() {
      if (!this.brand_range_id) return;
      var url = `/admin/brands/${this.brand_id}/groups?ranges[]=${this.brand_range_id}`;

      this.apiCall(url).then(res => (this.brand_groups = res.data));
    },

    fetchFeatures: function() {
      if (this.brand_id) {
        var url = `/admin/brands/${this.brand_id}/all_features`;
      } else {
        var url = `/admin/brands/nil/all_features`
      }

      this.apiCall(url).then(res => (this.features = res.data));
    },

    fetchMaterials: function() {
      var query = `?material_type_id=${this.material_type_id}&brand_id=${this.brand_id}&brand_range_id=${this.brand_range_id}&brand_group_id=${this.brand_group_id}&feature_id=${this.feature_id}`;
      var url = `/admin/materials${query}`;

      this.apiCall(url).then(res => {
        this.materials = res.data.materials.map(m => {
          return { ...m, selected: false };
        });
      });
    },

    toggleMaterialSelect: function(index) {
      var updatedMaterials = [...this.materials];

      updatedMaterials = this.materials.map((m, i) => {
        if (i === index) {
          return { ...m, selected: !m.selected, default: false }
        }

        return { ...m, selected: false, default: false };
      });

      this.materials = updatedMaterials;
    },

    setDefault: function(e, index) {
      var checked = e.target.checked;
      var updatedMaterials = this.materials.map((m, i) => {
        m.default = index === i && checked ? true : false;
        m.selected = index === i ? true : false;

        return m;
      });

      this.materials = updatedMaterials;
    },

    saveCarcassMaterial: function() {
      if (this.materials.findIndex(m => m.selected) === -1) {
        this.errors = ['A material must be selected to nominate a carcass material.'];
        return;
      }

      var url = `/admin/carcass_materials`;
      var method = "POST";
      var nominatedMaterial = this.materials.find(m => m.selected);
      var carcassMaterialParams = {
        authenticity_token: document.getElementsByName("csrf-token")[0].content,
        carcass_material: {
          default: nominatedMaterial.default,
          material_id: nominatedMaterial.id
        }
      };

      this.apiCall(url, method, carcassMaterialParams).then(res => {
        if (res.data.success) {
          this.successMessage = "Carcass material successfully nominated.";
        }
      }).catch(error => {
        if (error.response.data.material_id && error.response.data.material_id.length > 0) {
          this.errors = ['This material has already been nominated as a carcass material and cannot be added again.']
        }
      });
    },

    closeError: function() {
      this.error = "";
    },

    apiCall: function(url, method = "get", data = {}) {
      var options = {
        url: url,
        method: method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8"
        },
        data: data
      };

      return axios(options);
    }
  }
};
</script>
