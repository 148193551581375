import Tagify from '@yaireo/tagify';

export function clearInput(element) {
  element.val('');
}

export function clearSelectInput(element) {
  var length = element.options.length;

  for (let i = 0; i < length; i++) {
    element.options[i] = null;
  }
}

export function setTags(input, whitelist = []) {
  if (input) {
    var tagify = new Tagify(input, {
      whitelist,
      enforceWhitelist: true
    });

    return tagify;
  }
}

export function updateTags(tagify, tags) {
  if (tagify) {
    tagify.removeAllTags();
    tagify.settings.whitelist = tags;
  }
}
