export default () => {
  var pricingForm = $('#pricing-form');

  if (pricingForm.length) {
    const priceType = $('#summary_price_price_type')
    const matrixForm = $('#matrix-upload');
    const matrixFields = matrixForm.find('input');

    const oemForm = $('#price-rows')
    const oemFields = oemForm.find('select,input');

    const toggleMatrix = () => {      
      matrixForm.removeClass('d-none');
      oemForm.addClass('d-none');
      oemFields.each(function() {
        this.disabled = true;
      });
      matrixFields.each(function() {
        this.disabled = false;
      });
    }
  
    const toggleOem = () => {
      matrixForm.addClass('d-none');
      oemForm.removeClass('d-none');
      oemFields.each(function() {
        this.disabled = false;
      });
      matrixFields.each(function() {
        this.disabled = true;
      });
    }

    if (priceType.val() == 'matrix_price') {
      toggleMatrix();
    } else {
      toggleOem();
    }

    priceType.on('change', function(e) {
      e.preventDefault();

      const inputVal = e.target.value;

      if (inputVal === 'matrix_price') {
        toggleMatrix();
      } else {
        toggleOem();
      }
    });
  }
};
