export default () => {
  var estimatePage = $('#estimate-page');

  if (estimatePage) {
    var externalColours = [];
    var internalColours = [];
    var hardwareColours = [];

    var cabinetCategoryId = $('#estimate_cabinet_cabinet_category_id');
    var cabinets = $('#estimate_cabinet_cabinet_id');

    var hardwareBrand = $('#estimate_hardware_brand_id');
    var hardwareRange = $('#estimate_hardware_range_id');
    var hardwareColour = $('#estimate_hardware_colour_id');

    var internalBrand = $('#estimate_internal_brand_id');
    var internalRange = $('#estimate_internal_range_id');
    var internalColour = $('#estimate_internal_colour_id');
    var internalFinish = $('#estimate_internal_finish_id');

    var externalBrand = $('#estimate_external_brand_id');
    var externalRange = $('#estimate_external_range_id');
    var externalColour = $('#estimate_external_colour_id');
    var externalFinish = $('#estimate_external_finish_id');

    var existingHardwareBrandId = $('#existing_hardware_brand_id').val();
    var existingHardwareRangeId = $('#existing_hardware_range_id').val();
    var existingHardwareColourId = $('#existing_hardware_colour_id').val();

    var existingExternalBrandId = $('#existing_external_brand_id').val();
    var existingExternalRangeId = $('#existing_external_range_id').val();
    var existingExternalColourId = $('#existing_external_colour_id').val();
    var existingExternalFinishId = $('#existing_external_finish_id').val();

    var existingInternalBrandId = $('#existing_internal_brand_id').val();
    var existingInternalRangeId = $('#existing_internal_range_id').val();
    var existingInternalColourId = $('#existing_internal_colour_id').val();
    var existingInternalFinishId = $('#existing_internal_finish_id').val();

    // Are there existing values for the external and internal boards?
    if (existingHardwareBrandId) {
      updateHardwareRange(existingHardwareBrandId, function(res) {
        if (existingHardwareRangeId) {
          hardwareRange.find(`option[value="${existingHardwareRangeId}"]`).prop('selected', 'selected');

          updateHardwareColour(existingHardwareRangeId, function(res) {
            if (existingHardwareColourId) {
              hardwareColour.find(`option[value="${existingHardwareColourId}"]`).prop('selected', 'selected');
            }
          });
        }
      });
    }

    if (existingExternalBrandId) {
      updateExternalRange(existingExternalBrandId, function(res) {
        if (existingExternalRangeId) {
          externalRange.find(`option[value="${existingExternalRangeId}"]`).prop('selected', 'selected');

          updateExternalColour(existingExternalRangeId, function(res) {
            if (existingExternalColourId) {
              externalColour.find(`option[value="${existingExternalColourId}"]`).prop('selected', 'selected');

              updateExternalFinish(existingExternalColourId, function(res) {
                if (existingExternalFinishId) {
                  externalFinish.find(`option[value="${existingExternalFinishId}"]`).prop('selected', 'selected');
                }
              });
            }
          });
        }
      });
    }

    if (existingInternalBrandId) {
      updateInternalRange(existingInternalBrandId, function(res) {
        internalRange.find(`option[value="${existingInternalRangeId}"]`).prop('selected', 'selected');

        updateInternalColour(existingInternalRangeId, function(res) {
          if (existingInternalColourId) {
            internalColour.find(`option[value="${existingInternalColourId}"]`).prop('selected', 'selected');

            updateInternalFinish(existingInternalColourId, function(res) {
              if (existingInternalFinishId) {
                internalFinish.find(`option[value="${existingInternalFinishId}"]`).prop('selected', 'selected');
              }
            });
          }
        });
      });
    }

    hardwareBrand.change(function(e) {
      var brandId = parseInt($(this).val());

      if (!brandId) { return }

      updateHardwareRange(brandId);
    });

    externalBrand.change(function(e) {
      var brandId = parseInt($(this).val());

      if (!brandId) { return }

      updateExternalRange(brandId);
    });

    internalBrand.change(function(e) {
      var brandId = parseInt($(this).val());

      if (!brandId) { return }

      updateInternalRange(brandId);
    });

    hardwareRange.change(function(e) {
      var rangeId = parseInt($(this).val());

      if (!rangeId) { return }

      updateHardwareColour(rangeId);
    });

    externalRange.change(function(e) {
      var rangeId = parseInt($(this).val());

      if (!rangeId) { return }

      updateExternalColour(rangeId);
    });

    internalRange.change(function(e) {
      var rangeId = parseInt($(this).val());

      if (!rangeId) { return }

      updateInternalColour(rangeId);
    });

    externalColour.change(function() {
      // Request the new finishes appropriate for this colour
      var colourId = parseInt($(this).val());

      if (!colourId) { return }

      updateExternalFinish(colourId);
    });

    internalColour.change(function() {
      // Request the new finishes appropriate for this colour
      var colourId = parseInt($(this).val());

      if (!colourId) { return }

      updateInternalFinish(colourId);
    });

    cabinetCategoryId.change(function() {
      var categoryId = parseInt($(this).val());

      updateCabinetOptions(categoryId);
    });

    function updateHardwareRange(brandId, callback = function() {}) {
      requestBrandRanges(brandId, 3, function(res) {
        var ranges = res.map(r => ({ id: r.id, name: r.name }));
        updateSelectOptions(hardwareRange, ranges);

        callback(res);
      });
    }

    function updateExternalRange(brandId, callback = function() {}) {
      requestBrandRanges(brandId, 1, function(res) {
        var ranges = res.map(r => ({ id: r.id, name: r.name }));
        updateSelectOptions(externalRange, ranges);

        callback(res);
      });
    }

    function updateInternalRange(brandId, callback = function() {}) {
      requestBrandRanges(brandId, 2, function(res) {
        var ranges = res.map(r => ({ id: r.id, name: r.name }));
        updateSelectOptions(internalRange, ranges);

        callback(res);
      });
    }

    function updateHardwareColour(rangeId, callback = function() {}) {
      requestRangeColours(rangeId, 3, function(res) {
        hardwareColours = res;

        var colours = res.map(r => ({ id: r.colour_id, name: r.name }));
        updateSelectOptions(hardwareColour, colours);

        callback(res);
      });
    }

    function updateExternalColour(rangeId, callback = function() {}) {
      requestRangeColours(rangeId, 1, function(res) {
        externalColours = res;

        var colours = res.map(r => ({ id: r.colour_id, name: r.name }));
        updateSelectOptions(externalColour, colours);

        callback(res);
      });
    }

    function updateInternalColour(rangeId, callback = function() {}) {
      requestRangeColours(rangeId, 2, function(res) {
        internalColours = res;

        var colours = res.map(r => ({ id: r.colour_id, name: r.name }));
        updateSelectOptions(internalColour, colours);

        callback(res);
      });
    }

    function updateExternalFinish(colourId, callback = function() {}) {
      var colourIndex = externalColours.findIndex(c => c.colour_id === parseInt(colourId) );

      if (colourIndex !== -1) {
        var newFinishes = externalColours[colourIndex].finishes;

        updateSelectOptions(externalFinish, newFinishes);
      }

      callback();
    }

    function updateInternalFinish(colourId, callback = function() {}) {
      var colourIndex = internalColours.findIndex(c => c.colour_id === parseInt(colourId) );

      if (colourIndex !== -1) {
        var newFinishes = internalColours[colourIndex].finishes;

        updateSelectOptions(internalFinish, newFinishes);
      }

      callback();
    }

    function requestBrandRanges(brandId, materialType, callback = function() {}) {
      var url = `/admin/brands/${brandId}/ranges.json?material_type=${materialType}`;

      $.ajax({ url }).done(res => callback(res) );
    }

    function requestRangeColours(brandRangeId, materialType, callback = function() {}) {
      var url = `/admin/brand_range_colours/${brandRangeId}.json?&material_type=${materialType}`;

      $.ajax({ url }).done(res => callback(res) );
    }

    function updateSelectOptions(element, options) {
      element.find('option').not(':first').remove();

      options.forEach((c, i) => {
        element.append(`
          <option value="${c.id}">${c.name}</option>
        `);
      });
    }

    function updateCabinetOptions(categoryId) {
      var url = `/admin/cabinets.json?cabinet_category_id=${categoryId}`;

      $.ajax({ url }).done(res => {
        var cabinetOptions = res.map(c => ({ id: c.id, name: c.name }) );

        updateSelectOptions(cabinets, cabinetOptions);
      });
    }
  }
};
